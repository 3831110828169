import throttleAsyncFunction    from '@/client/utilities/throttleAsyncFunction.js';
import getAsyncOps              from '@/client/extensions/composition/asyncOperations.js';
import {helpers}                from '@vuelidate/validators';

export default function (params) {
    let validator = (value) =>  {
        console.log(params.list, value);
        try {
            params.list.includes(value)
        } catch (e) {
            console.log(e);
        }
        return params.list.includes(value);
    };

    return helpers.withParams(
        {type: 'inList'},
        validator
    );
};
