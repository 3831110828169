<template>

    <Skeleton
        :class="skeletonClass"
        template="form"
        :fitHeight="false"
        :defaultHeight="skeletonHeight"
        :count="expectedFields"
        :contentReady="asyncStatus.asyncDataReady">
        <form class="uk-form-stacked uk-margin"
              :class="formClass"
              v-if="asyncStatus.asyncDataReady"
              :method="formMethod"
              :disabled="formDisabled"
              :action="action"
              @submit.prevent="handleSubmit()"
              @reset.prevent="clear(), untouchAll()">

            <div class="fields"  :class="finalFieldWrapperClass">

                <alert
                    class="form-alert"
                    v-if="showSubmitError"
                    type="danger"
                    :animation="true"
                    close-type="event"
                    @alert:closing="hideSubmitError">
                    {{finalGeneralErrorMessage}}
                </alert>
                <component
                    v-for="(group, groupKey) in getGroupedFields()"
                    key="groupKey"
                    :is="group.component"
                    v-bind="group.props"
                >
                    <component
                        v-for="(field, fieldIndex) in getGroupFieldsForRendering(group)"
                        :is="field.component"
                        v-bind="field"
                        :error-display-position="errorDisplayPosition"
                        :key="groupKey+'-field-'+fieldIndex"
                        v-model="formData[field.name]"
                        @focusout="allowFieldErrorDisplay(fieldIndex)"
                        @input="touchInput(fieldIndex)"
                        :validation-pending="isFieldValidationPending(fieldIndex)"
                        :error-message="getFieldValidationErrorMessage(fieldIndex)"
                    ></component>


                </component>


                <slot name="afterFields"></slot>
                <component
                    v-for="(group, groupKey) in getGroupedButtons()"
                    key="groupKey"
                    :is="group.component"
                    v-bind="group.props">

                    <component
                        v-for="(button, buttonIndex) in group.buttons"
                        :is="button.component"
                        :disabled="buttonsDisabled"
                        :loading="buttonsLoadingStatus[groupKey][buttonIndex]"
                        :key="groupKey+'-button-'+buttonIndex"
                        v-bind="getButtonConfig(buttonIndex)">
                        {{safeTranslate(button.text)}}
                    </component>
                </component>
                <slot name="afterButtons"></slot>
                <div class="recaptcha-information">
                    <div v-show="preflightTypes.includes('recaptcha')"
                         class="recaptcha-text"
                         style="font-size: 8px; margin-top: 3px;">
                        <span>* Protected by Google reCAPTCHA</span>, <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">TOS</a> apply.
                    </div>
                </div>
            </div>



            <spinner v-if="showLoadingOverlay"
                     :text="overlaySpinnerText"
                     overlay="absolute"
                     :show="true"></spinner>
        </form>

    </Skeleton>

</template>

<script>
import useValidation from "@/client/extensions/composition/useValidation";

const _ = require('lodash/object');
import asyncOperations  from '@/client/extensions/composition/asyncOperations.js';
import useRecaptcha  from '@/client/extensions/composition/useRecaptcha.js';
import delayAsync from '@/client/utilities/delayAsync.js';
import { reactive, computed, watchEffect, nextTick, getCurrentInstance } from 'vue';

import useVuelidate from '@vuelidate/core'

export default {
    setup (props, context) {
        let {asyncOps, asyncOpsReady, asyncStatus,} = asyncOperations(props);
        let {isCaptchaReady, executeCaptcha} = useRecaptcha();
        return {
            v$ : useVuelidate(),
            asyncOps, asyncOpsReady, asyncStatus,
            ...useValidation(props),
            isCaptchaReady,
            executeCaptcha
        }
    },
    props: {
        /**
         * Placeholder for our model value, so that we can use v-model on the entire form data
         */
        modelValue : {
            type: Object,
            default() {return {};},
        },
        /**
         * Object containing fields. Props should be similar to what the FormInput component takes (most end up on a FormInput)
         * Is merged into property config. It is possible to just put this under config
         * Interesting possible keys:
         * - group     - key in property groups, assign the field into it. A group renders a component around all it's inputs
         * - component - allows to use a different component than FormInput. Good for custom HTML in the form
         * - autoTranslate - will make the form input not translate any string keys that can be translated (for quick development without language strings)
         */
        fields: {
            type: Object,
            default() {return {};},
        },
        /**
         * Class to customise the field wrapper
         **/
        fieldWrapperClass: {
            type: String,
            default: ''
        },
        /**
         * Object containing buttons. Props should be similar to what the FormButton component takes (most end up on a FormButton)
         * Is merged into property config. It is possible to just put this under config
         * Interesting possible keys:
         * - group     - key in property groups, assign the button into it. A group renders a component around all it's buttons
         * - component - allows to use a different component than FormInput. Good for custom HTML in the form
         * - autoTranslate - will make the form input not translate any string keys that can be translated (for quick development without language strings)
         */
        buttons: {
            type: Object,
            default() {return {};},
        },
        /**
         * Groups into which fields and buttons can be rendered. Order is important. Buttons will render after inputs regardless (so a group may render twice if input and button are both assigned)
         * false means no groups. result is that everything is rendred in a "transparent" container (= no container in html)
         * Keys should match the keys used to assign buttons and fields into the groups
         *
         * Property "component" deterimes the component to be used to wrap the group. default is CleanWrapper
         * Property "props" - object, it's properties will be bound to the group component
         */
        groups: {
            type: [Object, Boolean],
            default: false
        },
        /**
         * Set a margin on the fields container. Usefull when there are buttons.
         * When there are not , you may want to turn this off
         **/
        fieldWrapperMargin: {
            type: Boolean,
            default: true,
        },
        /**
         * Render context.
         * Some fields and buttons may provide a contexts (plural) object.
         * If this matches a key in a field's or a button's contexts, the context content will be sued as override.
         * So if a field has name of "test" and contexts: {secondContext:{name:'test2'}}:
         * the field uses name = test. But if the form context is "secondContext", the name will be test2.
         *
         * This allows us to define for example edit/create form in one form definition, and render by context (edit /create)
         */
        context: {
            type: String,
            default: 'default',
        },
        /**
         * Allows fine tuned control on the form behaviour, particularly the submit
         **/
        formHandling: {
            type: Object,
            default() {return {};},
        },
        /**
         * Deliver the entire form config - fields, buttons, groups, context here as one object
         * This component will merge in internally these keys, if the config doesnt have them
         */
        config: {
            type: Object,
            default() {return {};},
        },
        /**
         * Form action
         **/
        action: {
            type: String,
            default: '#'
        },
        /**
         * Form method
         **/
        method: {
            type: String,
            default: 'post',
        },
        /**
         * Is the form disabled
         **/
        disabled: {
            default: false
        },
        /**
         * Custom general error message that shows in general error cases
         * Use a language string (not the final actual translated text)
         * False uses default
         **/
        generalErrorMessage : {
            type: [String],
            default: 'core.form.genericSubmitError',
        },
        /**
         * FormPath
         * If provided, form will automatically load config from server from this path
         * Form will then overload it with the rest of the configuration provided in these props
         **/
        formPath : {
            type: [String, Boolean],
            default: false,
        },
        /**
         * Number of expected fields. For the loading ui
         *
         **/
        expectedFields: {
            type: [Number],
            default: 3,
        },
        /**
         * Height of each control in the form. This is only used for skeleton loader
         **/
        controlGroupHeight: {
            type: [Number],
            default: 85
        },
        /**
         * Position of errors
         * Available options: relative, absolute
         **/
        errorDisplayPosition: {
            type: String,
            default: 'relative'
        },
        class: {
            type: String,
            default : '',
        }
    },
    data: function () {
        let staticStructure = {
            formData : {},
            formServerConfig: {},
            hasServerConfig: false,
            formDisabled : false,
            loading: false,
            showSubmitError: false,
            generalErrorMessageInternal : false,
            mayShowErrors: [],
        };

        let dynamicStructure = {};

        if (this.formPath) {
            dynamicStructure.hasServerConfig = true;
            dynamicStructure.asyncData = {
                formServerConfig: this.formPath,
            };

        }

        return {...staticStructure, ...dynamicStructure}
    },
    emits: [
        'update:modelValue',
        'submit',
        'form:ready',
        'form:submissionAttemptBlocked',
        'form:preflightStart', 'form:preflightEnd', 'form:preflightSuccess', 'form:preflightError',
        'form:postFlightStart', 'form:postFlightEnd','form:postFlightError','form:postFlightSuccess',
        'form:submitStart'   , 'form:submitEnd'   ,'form:submitSuccess'    , 'form:submitError',
    ],
    computed: {
        safeFormData () {
            let safe = Object.assign({}, this.formData || {});
            return reactive(safe);
        },
        finalFieldWrapperClass () {
            let result = this.fieldWrapperClass;
            if ( this.fieldWrapperMargin) {
                result = result + ' ' + 'uk-margin-medium-bottom'
            }

            return result;
        },
        finalConfig () {
            let serverConfig = Object.assign({}, this.formServerConfig);
            let propertyConfig = Object.assign({}, this.config);


            let final = _.merge({}, serverConfig,propertyConfig);

            // make sure base keys are present, defaulting to props if missing
            ['fields','buttons','groups', 'context', 'formHandling'].forEach((key) => {
                final[key] = final[key] || this[key];
            });

            return final;
        },
        // todo: all these final methods - to actuall return final data, with deep inegrity. right now we have to use the get[x]Config for each entry
        finalFields () {
            let fields = Object.assign(this.finalConfig.fields);
            let result = {};
            let context = this.finalConfig.context;
            for (const [key, field] of Object.entries(fields)) {
                let omit = false;
                let contextOmit;

                // first, if we have a global default omit behaviour - apply that
                if (_.get(field, 'omit', false)) {
                    omit = field.omit;
                }

                // look for omit in our context, if exists, override what we have
                contextOmit = _.get(field, 'contexts.'+context+'.omit', null);

                if (typeof contextOmit === 'boolean') {
                    omit = contextOmit;
                }

                if (omit) {
                    continue;
                }
                result[key] = field;
            }
            // check for omit

            return result;
        },
        finalButtons() {
            return this.finalConfig.buttons
        },
        finalGroups() {
            return Object.assign({defaultGroup: {component:'CleanWrapper'}}, this.finalConfig.groups);
        },
        finalFormHandling() {
            return this.finalConfig.formHandling
        },
        buttonsDisabled () {
            return this.disabled !== false;
        },
        buttonsLoading () {
            return this.loading || this.asyncStatus.loading
        },
        buttonsLoadingStatus () {

            let result =  {

            };

            for( const [groupIndex, group] of Object.entries(this.getGroupedButtons())) {
                result[groupIndex] = {};
                for( const [index, button] of Object.entries(group.buttons)) {
                    // todo: maybe limit this to only submit buttons
                    result[groupIndex][index] = (this.loading || this.asyncStatus.loading);
                }
            }

            return reactive(result);
        },
        formMethod () {
            if (this.method === 'get') {
                return 'get';
            }

            return 'post';
        },
        submitMethod () {
            let lowerCaseMethod = this.method.toLowerCase();

            if ( ['get','post','put','patch'].includes(lowerCaseMethod)) {
                return lowerCaseMethod;
            } else {
                return 'post';
            }
        },
        showLoadingOverlay () {
            let handlingConf = this.getFormHandlingConfig();

            return (this.asyncStatus.loading && handlingConf.loadingBehaviour.overlay);
        },
        overlaySpinnerText () {
            return this.$t(this.getFormHandlingConfig().loadingBehaviour.text);
        },
        finalGeneralErrorMessage () {
            if (this.generalErrorMessageInternal) {
                return this.$t(this.generalErrorMessageInternal)
            } else {
                return this.$t(this.generalErrorMessage)
            }

        },
        skeletonHeight () {
            // 85 px for each form field, + another such unit for the button
            return (this.expectedFields +1) * this.controlGroupHeight;
        },
        preflightTypes() {
            let result = [];
            let preflights =  this.getFormHandlingConfig().preflight;

            // support preflights configured as object, not only as array
            if (preflights && typeof preflights === 'object') {
                preflights = Object.values(preflights);
            }

            preflights.forEach((item) => {
                if(typeof item && item && item.type) {
                    result.push(item.type);
                }
            });

            return result;
        },
        isTouched () {
            return this.v$.$anyDirty;
        },
        isValid () {

            return  [...this.v$.$errors].length === 0;
        },
        skeletonClass () {
            if (this.class) {
                return 'form-skeleton form-skeleton--'+this.class;
            }

            return 'form-skeleton';
        },
        formClass () {
            if (this.class) {
                return 'form form--'+this.class;
            }

            return 'form';
        }
    },
    methods : {
        getFieldConfigByField (field) {
            let conf = Object.assign({}, field);

            conf.component = conf.component || 'FormInput';

            // implement context
            if ( ! conf.hasOwnProperty('contexts')) {
                conf.contexts = {};
            }

            // no context overrides detected
            if ( ! conf.contexts.hasOwnProperty(this.finalConfig.context)) {
                return conf;
            }

            for (const [key, value] of Object.entries(conf.contexts[this.finalConfig.context])) {
                conf[key] = value;
            }

            return conf;
        },
        /**
         * Get field config, that is safe. Based on name
         **/
        getFieldConfig(key) {
            let conf = {};
            let fields = this.getFields();

            if (typeof fields[key] === 'undefined') {
                debug('Accessing field that is not defined in config', 2, {this:this, name:key});
                return conf;
            }

            return this.getFieldConfigByField(fields[key]);
        },
        /**
         * Get button config, that is safe. Based on name
         **/
        getButtonConfig(key) {
            let button = Object.assign({}, this.finalButtons[key]);
            let buttonClass = button.class || '';

            if (typeof button.type !== 'string') {
                button.type = 'submit';
            }

            if (button.type === 'submit') {
                button.class = buttonClass + ' uk-button-primary';
            }

            if (typeof button.text !== 'string') {
                button.text = 'core.form.submit';
            }

            // implement context
            if ( ! button.hasOwnProperty('contexts')) {
                button.contexts = {};
            }

            if (typeof button.component !== 'string') {
                button.component = 'FormButton';
            }

            // no context overrides detected
            if ( ! button.contexts.hasOwnProperty(this.finalConfig.context)) {
                return button;
            }

            for (const [key, value] of Object.entries(button.contexts[this.finalConfig.context])) {
                button[key] = value;
            }

            return button;
        },
        /**
         * Get fields, in groups
         **/
        getGroupedFields () {
            let rawGroups   = (this.getGroups() || {'defaultGroup':{}});
            let fields      = Object.assign({}, this.getFields());
            let groups      = Object.assign({}, rawGroups);
            let final       = {};

            // enforce integrity on group structure
            Object.entries(groups).map(([key, group]) => {
                final[key] = {
                    component: group.component || 'CleanWrapper',
                    props    : group.props     || {},
                    fields   : {}
                };
            });

            // assign fields to groups
            for (const [key, field] of Object.entries(fields)) {
                let targetGroup = field.group || 'defaultGroup';

                if ( ! final.hasOwnProperty(targetGroup)) {
                    targetGroup = 'defaultGroup';
                }

                final[targetGroup].fields[key] = this.getFieldConfig(key);
            }


            // final result. only take groups that actually have fields in them
            let cleanGroups = {};
            for (const [key, group] of Object.entries(final)) {
                if (Object.entries(group.fields).length > 0) {
                    cleanGroups[key] = group;
                }
            }

            return cleanGroups;
        },
        /**
         * Get field groups for the layout
         **/
        getGroupFieldsForRendering (group) {
            let fields = group.fields;
            let result = {};

            for (const [key, field] of Object.entries(fields)) {
                if (this.shouldRenderField(field)) {
                    result[key] = field;
                } else {
                    // we are hiding the field. Hide error messages too, if exist
                    this.untouchField(key)
                }
            }

            return result;
        },
        /**
         * Get buttons, in groups
         **/
        getGroupedButtons() {
            let rawGroups   = (this.getGroups() || {'defaultGroup':{}});
            let buttons     = Object.assign({}, this.getButtons());
            let groups      = Object.assign({}, rawGroups);
            let final       = {};

            // enforce integrity on group structure
            Object.entries(groups).map(([key, group]) => {
                final[key] = {
                    component: group.component || 'CleanWrapper',
                    props    : group.props     || {},
                    buttons  : {}
                };
            });

            // assign buttons to groups
            for (const [key, button] of Object.entries(buttons)) {
                let targetGroup = button.group || 'defaultGroup';

                if ( ! final.hasOwnProperty(targetGroup)) {
                    targetGroup = 'defaultGroup';
                }

                final[targetGroup].buttons[key] = this.getButtonConfig(key);
            }

            // prepare the final result. only take groups that actually have buttons in them
            let cleanGroups = {};
            for (const [key, group] of Object.entries(final)) {
                if (Object.entries(group.buttons).length > 0) {
                    cleanGroups[key] = group;
                }
            }

            return cleanGroups;

        },
        getConfig () {
            return this.finalConfig;
        },
        getFields () {
            return this.finalFields;
        },
        getFieldNames () {
            return Object.keys(this.getFieldNames());
        },
        getButtons () {
            return this.finalButtons;
        },
        getGroups () {
            return this.finalGroups;
        },
        enforceFormDataIntegrity () {
            let availableKeys = [];

            Object.keys(this.getFields()).forEach((key) => {
                availableKeys.push(this.getFields()[key].name);
            });
            availableKeys.forEach((key) => {
                if (typeof this.formData[key] === 'undefined') {
                    this.formData[key] = this.modelValue[key] ||  this.getFieldConfig(key).defaultValue;
                }
            });

            Object.keys(this.formData).forEach((key) => {
                if ( ! availableKeys.includes(key)){
                    // delete this.formData[key];
                }
            });
        },
        isFieldValidationPending(name) {
            if (typeof this.v$.formData === 'undefined' || typeof this.v$.formData[name] == 'undefined') {
                return false;
            }
            return this.v$.formData[name].$pending;
        },
        renderConditionPasses(field, name, data) {
            // todo: refactor into a more clever structure
            if (name === 'otherFieldValue') {
                if(this.formData[data.target] !== data.value) {
                    return false;
                }
            }

            return true;
        },
        /**
         * Determines if a field should be rendered
         * @param field
         */
        shouldRenderField(field) {


            // unless specified, there are no render limits
            if ( ! field.hasOwnProperty('renderConditions')) {
                return true;
            }
            for (const [name, data] of Object.entries(field.renderConditions)) {
                if ( ! this.renderConditionPasses(field, name, data)) {
                    return false;
                }
            }

            return true;
        },
        getFieldValidationErrorMessage :   function (name) {
            let validationMessage = [];

            // field has no validations and therefore is considered valid
            if ( typeof this.v$.formData === 'undefined' || typeof this.v$.formData[name] === 'undefined') {
                return '';
            }

            if ( ! this.mayShowErrors.includes(name)) {
                return '';
            }

            // if a validation is pending, hold off on displaying errors
            if (this.isFieldValidationPending(name)) {
                return '';
            }

            if (typeof this.v$.formData[name].$errors === 'undefined' || this.v$.formData[name].$errors.length < 1) {
                return '';
            }

            this.v$.formData[name].$errors.map((validation) => {
                validationMessage.push(validation.$message);
            });

            return validationMessage.join(', ');
        },
        allowFieldErrorDisplay(name) {
            if ( ! this.mayShowErrors.includes(name)) {
                this.mayShowErrors.push(name);
            }

        },
        allowFieldErrorDisplayAll() {
            let fields = this.finalFields;
            for (const [index, value] of Object.entries(fields)) {
                this.allowFieldErrorDisplay(index);
            }

        },
        touchInput(name) {
            if (typeof this.v$.formData !== 'undefined' && typeof this.v$.formData[name] !== 'undefined') {
                this.v$.formData[name].$touch();
            }

            let field = this.getFieldConfig(name);
            let eagerValidation = field.hasOwnProperty('eagerValidation') ? field.eagerValidation  : false;
            if ( eagerValidation ) {
                this.allowFieldErrorDisplay(name);
            }


        },
        doesFieldHaveValidationError(name) {
            return this.getFieldValidationErrorMessage(name) !== '';
        },
        getFormHandlingConfig () {
            let base = this.finalFormHandling;
            let loadingBehaviour = {overlay: false, text: 'core.form.loading'};
            if (typeof base !== 'object' || base === null) {
                base = {};
            }

            let argLoadingBehaviour = base.loadingBehaviour || {};

            loadingBehaviour = _.merge(loadingBehaviour, argLoadingBehaviour);


            return {
                requestAdapter      : base.hasOwnProperty('requestAdapter')   ? base.requestAdapter   : 'default',
                responseAdapter     : base.hasOwnProperty('responseAdapter')  ? base.responseAdapter  : 'default',
                selfHandling        : base.hasOwnProperty('selfHandling')     ? base.selfHandling     : true,
                loadingBehaviour    : loadingBehaviour,
                successBehaviour    : base.hasOwnProperty('successBehaviour') ? base.successBehaviour : 'default',
                preflight           : base.hasOwnProperty('preflight')        ? base.preflight        : [{type: 'validation'}],
                postFlight          : base.hasOwnProperty('postFlight')       ? base.postFlight       : [],
            }
        },
        אhandleSubmitSimple () {
            this.$emit('submit');
            return true;
        },
        isButtonLoading(index) {
            let conf = this.getButtonConfig(index);
            return this.buttonsLoading && conf.type === 'submit';
        },
        hasPreflight(type) {

        },
        async doPreflightCustom (options) {
            if (typeof options.handler !== 'function') {
                debug('Custom preflight configuration error, missing handler. preflight failing', '2', options);
                return new Promise((fulfil, reject)=>{reject();});
            }

            // return {passed: false,  data: result.data};
            return new Promise((fulfil, reject)=> {
                options.handler(fulfil, reject, this);
            }).then(
                (result) => ({passed: true,  data: result  }),
                (result) => ({passed: false, data: result })
            );
        },
        async doPreflightRecaptcha (options) {

            return new Promise(async (fulfil, reject) => {
                let captchaResult = await this.executeCaptcha(options);

                if( ! captchaResult.isError) {
                    this.formData.securityChallenge = captchaResult.token;
                } else {
                    this.formData.securityChallenge = null;
                    debug('Captcha error.. should we do error handling in ui?', 2);
                    reject({passed: false,  data:captchaResult});
                }


                // TODO: push captcha into formData
                fulfil({passed: true,  data:captchaResult});
            });
        },
        async doPreflightValidation (options) {

            let getValidationReportAndNotify = () => {
                let result = {passed: ! Boolean(this.v$.$error), data: this.v$.$error };

                if (this.v$.$error) {
                    this.$saffron.ui.clearAndNotify('<span uk-icon="close"></span>'+this.safeTranslate('validation.formInvalid'), 'danger');
                }

                return result;
            };

            let respondPromiseWhenNotPending = async () => {
                // watch pending
                return new Promise(async (resolve, reject) => {
                    if ( ! this.v$.$pending) {

                        return resolve(getValidationReportAndNotify());
                    }

                    watchEffect(async ()=>{
                        if (this.v$.$pending === false) {
                            return resolve(getValidationReportAndNotify());
                        }
                    })
                });
            };

            // do validation - touch the inputs to enable async validation, touch them again to run the async validations
            this.v$.$touch();

            await delayAsync(this.v$.$touch, 50);

            // allow error display
            this.allowFieldErrorDisplayAll();

            // may be coved by // await delayAsync(this.v$.$touch, 50);. build to see if this is a dev issue
            nextTick(() => { this.v$.$touch();});

            // return the response. if vuelidate is pending, this will wait until it is done waiting
            return await respondPromiseWhenNotPending();
        },
        async doPreflightConfirmation (options) {
            let modalOptions = {...options};
            let title   = options.title     || 'core.form.confirm.modalDefaultTitle';
            let content = options.content   || 'core.form.confirm.modalDefaultContent';

            return this.$saffron.ui.modal.confirm(content, title, modalOptions).then(
                () => ({passed: true,  data: true  }),
                () => ({passed: false, data: false })
            );
        },
        async doPreflightServer (options) {
            let conf   = this.getFormHandlingConfig(),
                url = options.url || false,
                additionalData = options.additionalData || {},
                startCallback = options.onStart || options.startCallback ||  function (){},
                endCallback = options.onEnd || options.endCallback || function () {},
                finalData, callOptions;


            startCallback(this);
            // validate url
            if (typeof url !== 'string') {
                debug('Can not perform server preflight, options must have a URL that is a string', 2, {component:this, options:options});
                return {passed: false,  data: {}  };
            }

            // allow disregarding of a '/' prefix
            if (url.startsWith('/')) url = url.substring(1);

            // check for additonal data
            if (typeof additionalData !== 'object') {
                debug('Can not perform server preflight, options have an extraData property, but it is not an object', 2, {component:this, options:options});
                return {passed: false,  data: {}};
            }

            finalData = {...this.formData, ...additionalData};

            callOptions = {
                requestAdapter: conf.requestAdapter,
                responseAdapter: conf.responseAdapter,
                method: 'get'
            };

            let result = await this.asyncOps.asyncCall(url, this.formData, callOptions);

            endCallback(this, result);
            if (result.isError || result.code > 299 || result.code < 200) {
                return {passed: false,  data: result.data};
            } else {
                return {passed: true,  data: result.data};
            }


        },
        async doPreflight() {
            let runPreflightsAsync = async () => {
                let results = [];
                let passes = true;
                let preflights =  this.getFormHandlingConfig().preflight;

                // support preflights configured as object, not only as array
                if (preflights && typeof preflights === 'object') {
                    preflights = Object.values(preflights);
                }

                for (const preflight of preflights) {

                    // prepare vars
                    let type    = preflight.type || '',
                        options = preflight.options || {},
                        method  = 'doPreflight'+utilities.ucFirst(type),
                        result  = {
                            type    : type,
                            skipped : false,
                            passed  : null,
                            config: preflight,
                            data: null
                        };

                    // make sure method exists, and if not - halt the operation
                    if ( typeof this[method] !== 'function') {
                        debug('Can not run preflight - no supporting method for this type',2, {component: this, type: type, methodName: method, preflightConfig:preflight});
                        result.skipped = true;
                        result.passed = false;
                        results.push(result);
                        passes = false;
                        break;
                    }

                    result.skipped = false;
                    result         = {...result, ...(await this[method](options))};

                    results.push(result);

                    // halt the operation if a preflight fails
                    if ( ! result.passed) {
                        passes = false;
                        break;
                    }
                }

                // return the verdict, and a report of the process
                return {passes, results};
            };

            // todo: support preflight as async call
            // start preflight - validation
            this.$emit('form:preflightStart', {component:this, data:this.formData});

            let result = await runPreflightsAsync();

            if (result.passes) {
                this.$emit('form:preflightEnd',       {component:this, data:this.formData, result: true, info: result.results});
                this.$emit('form:preflightSuccess',   {component:this, data:this.formData, result: true, info:'success'});
                return true;
            } else {
                this.$emit('form:preflightEnd',   {component:this, data:this.formData, result: result.results});
                this.$emit('form:preflightError', {component:this, data:this.formData, result: result.results});
                return false;
            }
        },


        async doPostFlightCustom (options, submitResult) {
            let extraData, result;
            if (typeof options !== 'object' || options === null) {
                debug('post flight custom - invalid input, options must be an object', 2, options);
                return {passed: true,  data: {}};
            }

            if (typeof options.handler !== 'function') {
                debug('post flight custom - invalid input, options need to have a function under the "handler" key', 2, options);
                return {passed: true,  data: {}};
            }

            extraData = options.extraData || {};

            result = await options.handler(submitResult, extraData);


            return {passed: result,  data: {}};
        },

        async doPostFlightNotification (options, submitResult)
        {
            let text = '', type = 'success';

            if (typeof options === 'object' && options !== null) {
                text    = options.text  || text;
                type    = options.type  || type;
            }

            if (typeof options === 'string') {
                text = options;
            }

            this.$s.ui.notification(text, type);

            return {passed: true,  data: {}};
        },
        async doPostFlightClear(options, submitResult)
        {
            this.clear();
            return {passed: true,  data: {}};
        },

        async doPostFlight(submitResult) {
            let result = {};

            let runPostFlightsAsync = async () => {
                let results = [];
                let passes = true;

                for (const postFlight of this.getFormHandlingConfig().postFlight) {
                    // prepare vars
                    let type    = postFlight.type || '',
                        options = postFlight.options || {},
                        method  = 'doPostFlight'+utilities.ucFirst(type),
                        result  = {
                            type    : type,
                            skipped : false,
                            passed  : null,
                            config: postFlight,
                            data: null
                        };

                    // make sure method exists, and if not - halt the operation
                    if ( typeof this[method] !== 'function') {
                        debug('Can not run postflight - no supporting method for this type',2, {component: this, type: type, methodName: method, postFlightConfig:postFlight});
                        result.skipped = true;
                        result.passed = false;
                        results.push(result);
                        passes = false;
                        break;
                    }

                    result.skipped = false;
                    result         = {...result, ...(await this[method](options, submitResult))};

                    results.push(result);

                    // halt the operation if a preflight fails
                    if ( ! result.passed) {
                        passes = false;
                        break;
                    }
                }

                // return the verdict, and a report of the process
                return {passes, results};
            };


            this.$emit('form:postFlightStart', {component:this, data:this.formData});

            result = await runPostFlightsAsync();

            this.$emit('form:postFlightEnd', {component:this, data:this.formData, isError: ! result.passes, postFlightResults: result.results});

            if ( ! result.passes) {
                this.$emit('form:postFlightError', {component:this, data:this.formData, isError:true, postFlightResults: result.results});
            } else {
                this.$emit('form:postFlightSuccess', {component:this, data:this.formData, isError:false, postFlightResults: result.results});
            }

            return {isError: ! result.passes, postFlightResults: result.results};
        },

        async submitAsync () {
            let conf   = this.getFormHandlingConfig();
            let action = this.action;
            let postFlightResult;

            this.$emit('form:submitStart', {component:this, data:this.formData});

            this.formDisabled = true;

            let callOptions = {
                requestAdapter: conf.requestAdapter,
                responseAdapter: conf.responseAdapter,
                method: this.submitMethod
            };

            if (action.startsWith('/')) {
                action = action.substring(1)
            }
            let result = await this.asyncOps.asyncCall(action, this.formData, callOptions);

            // success or fail event
            if (result.isError) {
                this.$emit('form:submitError', {component:this, data:this.formData, isError:result.isError, result});
            } else {
                this.$emit('form:submitSuccess', {
                    component:this,
                    formData: this.formData,
                    isError:result.isError,
                    result,
                    resultData: (result.data || null)
                });
            }

            // finish event
            this.$emit('form:submitEnd', {component:this, data:this.formData, isError:result.isError, result});

            // release the form
            this.formDisabled = false;

            // return the result
            return {isError: result.isError, result};
        },
        async handleSubmit () {
            let conf = this.getFormHandlingConfig(), postFlightResult;

            if (this.formDisabled) {
                this.$emit('form:submissionAttemptBlocked', {component:this, data:this.formData, 'reason':'form is disabled'})
            }

            // if we shouldn't handle ourselves, just pop a submit event, so parent can use @submit
            if( ! conf.selfHandling) {
                return this.handleSubmitSimple();
            }

            this.formDisabled = true;
            this.loading = true;

            // run a preflight, this fires events
            if ( ! await this.doPreflight()) {
                this.formDisabled = false;
                this.loading = false;
                return false;
            }

            // submit the form. this fires events
            let result =  await this.submitAsync();

            // submitAsync has already change formDisabled to false. But for the sake of order, lets do this here
            this.formDisabled = false;
            this.loading = false;

            if (result.isError) {
                this.showSubmitError = true;
                return false;
            }

            if ( result.isError) {
                return {isError: result.isError, result};
            }

            // disable the form for the post flight, run it, enable the form and retun the final result
            this.formDisabled = true;
            this.loading      = true;

            postFlightResult = await this.doPostFlight(result);

            this.formDisabled = false;
            this.loading      = false;

            if (postFlightResult.isError) {
                this.showSubmitError = true;
                return {isError: result.isError, result, postFlightResult};
            }

            // everything else up until now was ok, but it is possible that the postFlight did not pass. so it alone determines our isError status at this point

            this.hideSubmitError();
            return {isError: postFlightResult.isError, result, postFlightResult};
        },

        submit () {
            return this.handleSubmit();
        },
        hideSubmitError () {
            this.showSubmitError = false;
        },
        untouchAll () {
            this.v$.$reset();
        },
        untouchField(key) {
            // if helps in case form data is not correctly populated, to avoid error
            if (this.v$.formData && this.v$.formData[key]) {
                this.v$.formData[key].$reset();
            }

        },
        clear () {
            for (const [index, val] of Object.entries(this.formData) ) {
                this.formData[index] = null;
            }

            this.untouchAll();
        },
        getValidationRules() {
            const result = {};
            const fields = this.getFields();

            let getRepeatableRules = (rawField) => {
                if ( typeof rawField.fields !== 'object') {
                    return {};
                }

                let parentValidationConfig = rawField.validation || {};
                let finalResult;
                finalResult = this.getValidationsByConfig(parentValidationConfig, true);
                finalResult['$each'] = {};


                for (const [index, innerField] of Object.entries(rawField.fields)) {
                    let validationConfig = innerField.validation || {};
                    finalResult['$each'][index] = this.getValidationsByConfig(validationConfig, true);
                }


                return finalResult;
            };

            for (const [index, rawField] of Object.entries(fields)) {

                let validationConfig = this.getFieldConfig(index).validation || {};
                let type = rawField.type || 'text';

                // if the field should not be rendered it should be considered valid so that form can be submitted
                if ( ! this.shouldRenderField((rawField))) {
                    result[index] = {};
                    continue;
                }

                // for repeatable field, use repeatable validation magic
                if (type === 'repeatable') {
                    result[index]        = getRepeatableRules(rawField);
                    continue;
                }

                // default behaviour
                result[index]        = this.getValidationsByConfig(validationConfig, true);
            }

            return result;
        }
    },
    watch: {
        hasServerConfig: {
            handler: function (newVal) {
                if (newVal) {
                    // TODO: if form bugs, check this - it was broken and we enabled it, but for seems to have been working

                    this.enforceFormDataIntegrity();
                }
            },
            deep: true,
            immediate: true
        },
        // when setting our model from parent, update the formData which actually holds our data
        modelValue: {
            handler(newVal, oldVal) {
                this.formData = newVal;
                // make sure vuelidate is aware of this change

                // this.v$.formData.$model = this.formData;
            },
            deep: true,
            immediate: true
        },
        // when we update the form data, update the parent model
        formData: { // when our formData changs, update the parent

            handler: function (newVal, oldVal) {
                //  this.v$.formData.$model = this.formData;

                this.$emit('update:modelValue', this.formData);
            },
            deep: true,
            immediate: true
        },
        'asyncStatus.asyncDataReady' (newVal) {
            if (newVal === true) {
                this.$emit('form:ready', this.formData)
            }
        }
    },
    validations () {

        return {
            formData : reactive(this.getValidationRules() || {})
        }
    },
    created () {

        if (this.v$) {
            this.v$ = this.v$;
        }
        // this.v$.formData.$model = this.formData;
    },
    mounted () {

        // this.v$.formData.$model = this.formData;
        this.$emit('update:modelValue', this.formData)
    },
    serverPrefetch () {
        return this.getSSRPrefetchPromise();
    },
}
</script>

<style lang="scss">
// NON SCOPED STYLE - we can hide recaptcha badge because we handle the legal stuff ourselves
.grecaptcha-badge { visibility: hidden; }
</style>
<style scoped lang="scss">
// so alert plays nice with flex layouts
.form-alert {
    flex: 100%;
}

</style>
