import {reactive, watchEffect} from 'vue';

let debouncers = reactive({});

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

// todo: support args for handler
const throttleAsyncFunction = (handler, fulfilCallback, rejectCallback, options = {delay: 1000})  => {
    let finalOptions, identifier, delay;

    finalOptions = options;

    if (typeof options !== 'object' || options === null) {
        finalOptions = {};
    }

    identifier = finalOptions.identifier || getRandomInt(9999999);
    delay      = finalOptions.delay      || 1000;

    if (typeof debouncers[identifier] === 'undefined') {
        debouncers[identifier] = {
            awaiting: false,
            timeout: null
        }
    }

    clearTimeout(debouncers[identifier].timeout);

    debouncers[identifier].awaiting = true;

    debouncers[identifier].timeout = setTimeout(() => {
        debouncers[identifier].awaiting = false;
    }, delay);

    watchEffect(() => {
        if (debouncers[identifier].awaiting === false) {
            clearTimeout(debouncers[identifier].timeout);
            handler(fulfilCallback, rejectCallback).then(()=>{},()=>{});
        }
    });
};

export default throttleAsyncFunction;
