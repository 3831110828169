

import { defineAsyncComponent } from 'vue'
export default function (path, withDefineAsyncComponent = false) {

    let $overridePath = `@/overrides/client/${path}`; //`${$overridePath}`

    let promiseFactory = () =>  {

        return new Promise(async (resolve, reject) => {
            let result;

            if ( ! path) {
                return false
            }

       //     let overridePath = `@/overrides/client/${path}`; //`${overridePath}`

        //    result = await  import( /* webpackPrefetch: true */ `${overridePath}`).catch((e)=>{
          //      return import( /* webpackPrefetch: true */ `@/client/${path}`)
         //   });
            result = await  import( /* webpackPrefetch: true */ `@/client/${path}`);
            return resolve(result);
        });
    }

    if (withDefineAsyncComponent) {
        return defineAsyncComponent(promiseFactory);
    } else {
        return promiseFactory;
    }


}
