let mainRouterModule = false;

let context = require.context('@/', true, /\/overrides\/client\/router\/index\.js/);
context.keys().forEach(key => {
  if ('./overrides/client/router/index.js' === key) {
    mainRouterModule = context(key);
  }
});
if ( ! mainRouterModule) {
  mainRouterModule = require("@/client/router/router.js");
}

export default mainRouterModule.default;
